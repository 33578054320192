import consumer from "./consumer"

$(document).ready(function () {

  const messages_container = $('#messages_container');
  const chat_channel_id = $('#messages_container').data('chat-channel-id');

  console.log('ChatChannel chat_channel_id', chat_channel_id);

  const sent_by_id = messages_container.data('sent-by-id');
  console.log('ChatChannel sent_by_id', sent_by_id);

  const sent_by_type = messages_container.data('sent-by-type');
  console.log('ChatChannel sent_by_type', sent_by_type);


  let typingTimer = null;

  const chatChannel = consumer.subscriptions.create({
    channel: "ChatChannel",
    channel_id: chat_channel_id
  }, {
    connected() {
      console.log('ChatChannel connected');
    },

    disconnected() {
      console.log('ChatChannel disconnected');
    },

    received(data) {
      console.log('ChatChannel received data', data);
      const sender_user_type = data.sent_by_type.toLowerCase();
      console.log('ChatChannel received sender_user_type', sender_user_type);
      const receiver_user_type = sender_user_type === 'administrator' ? 'user' : 'administrator';
      console.log('ChatChannel received receiver_user_type', receiver_user_type);
      const current_user_type = $('body')[0].classList[0];
      console.log('ChatChannel received current_user_type', current_user_type);
      const typing_activity_indicator = $(`.typing_activity_indicator.${sender_user_type}_is_typing`);


      var isAtBottom;
      var windowSum = $('.imessage_chat')[0].scrollHeight - $('.imessage_chat').scrollTop() - $('.imessage_chat').innerHeight();

      console.log("windowSum", windowSum);
      if( windowSum < 30 ) {
        isAtBottom = true;
      }

      if (data.action_type === 'speak') {
        console.log("SPEAK");
        messages_container.append(data['message']);
        typing_activity_indicator.hide();
        if (current_user_type === receiver_user_type) {
          console.log("chatChannel WE WILL MARK MESSAGES AS READ!");
          const chat_url = receiver_user_type === 'administrator' ?
            "/admin/chats/" + data["channel_id"] + "/mark_as_read"
          :
            "/chats/" + data["channel_id"] + "/mark_as_read";

          console.log("ChatChannel mark_as_read chat_url", chat_url);
          $.post(chat_url, response => console.log('ChatChannel mark_as_read response', response));
          if (isAtBottom) {
            scrollToChatBottom();
          }
        } else {
          scrollToChatBottom();
        }

      } else if (data.action_type === 'is_typing') {
        typing_activity_indicator.show();
        clearTimeout(typingTimer);
        startTimer();
        if (isAtBottom) {
          scrollToChatBottom();
        }
      }
    },

    speak(text, channel_id, sent_by_id, sent_by_type) {
      console.log('ChatChannel speak', text, channel_id, sent_by_id, sent_by_type);
      this.perform('speak', {text, channel_id, sent_by_id, sent_by_type});
    },

    is_typing(channel_id, sent_by_id, sent_by_type) {
      console.log('ChatChannel is_typing', channel_id, sent_by_id, sent_by_type);
      this.perform('is_typing', {channel_id, sent_by_id, sent_by_type});
    }

  });


  $(document).on('keypress', '[data-behavior~=chat_speaker]', function(event) {
    console.log('keypress', event);
    console.log('event.key', event.key);

    console.log('keypress chat_channel_id', chat_channel_id);
    console.log('keypress sent_by_id', sent_by_id);
    console.log('keypress sent_by_type', sent_by_type);

    if ((event.key === 'Enter') && (event.target.value !== '')) {
      console.log('submit via return key', event.target.value);
      chatChannel.speak(event.target.value, chat_channel_id, sent_by_id, sent_by_type);
      event.target.value = '';
      return event.preventDefault();
    } else {
      return chatChannel.is_typing(chat_channel_id, sent_by_id, sent_by_type);
    }
  });


  $('.chat_form_elements button').click(function(event) {
    console.log('.chat_form_elements button click event', event);
    const input_element = $(this).parent().find('input');
    console.log('input_element', input_element);
    const input_element_value = input_element.val();
    console.log('input_element_value', input_element_value);

    if (input_element_value !== '') {
      chatChannel.speak(input_element_value, chat_channel_id, sent_by_id, sent_by_type);
      input_element.val('');
    }
    return event.preventDefault();
  });

  $('.scroll_to_bottom_button_chat').on('click', function(event) {
    console.log('chat_channel click!', event);
    scrollToChatBottom();
    return event.preventDefault();
  })

  $('.imessage_chat').on('scroll', function() {
    if (($(this).scrollTop() + $(this).innerHeight()) + 75 >= $(this)[0].scrollHeight) {
      $('.scroll_to_bottom_button_chat').fadeOut(250);
    } else {
      $('.scroll_to_bottom_button_chat').fadeIn(100);
    }
  });

  var startTimer = () => typingTimer = window.setTimeout((() => $(".typing_activity_indicator").fadeOut('slow')), 5000);

  var scrollToChatBottom = () => $('.imessage_chat').stop().animate(
    {scrollTop: messages_container.prop("scrollHeight")}
  , 500);

  const jumpToChatBottom = () => $('.imessage_chat').scrollTop(messages_container.prop("scrollHeight"));

  return jumpToChatBottom();

});

