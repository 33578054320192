window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/heif', 'image/heif-sequence', 'image/heic', 'image/heic-sequence']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only support attachment of jpeg, png, or heif image files")
  }
})

window.addEventListener("trix-file-accept", function(event) {
  const maxFileSize = 2500 * 2500
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Only support uploading of image files up to 5MB")
  }
})
