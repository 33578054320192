import consumer from "./consumer"

$(document).ready(function() {

  const toast_container = $('#toast_container');
  const administrator_notifications_container = $('#administrator_notifications_container');
  const administrator_notifications_menu_container = $('#administrator_notifications_menu_container');

  var administrator_channel_id = $("meta[name='administrator_channel_id']").attr("content");
  console.log('AdministratorNotificationChannel administrator_channel_id', administrator_channel_id);

  const chatChannel = consumer.subscriptions.create({
    channel: "AdministratorNotificationChannel",
    channel_id: administrator_channel_id
  }, {
    connected() {
      console.log('AdministratorNotificationChannel connected');
    },

    disconnected() {
      console.log('AdministratorNotificationChannel disconnected');
    },

    received(data) {
      console.log('AdministratorNotificationChannel received data', data);


      if (data.action_type === 'update_counters') {
        console.log('AdministratorNotificationChannel update_counters', data);

        return $.get("/admin/uncleared_messages_counters", function(counters) {
          console.log('counters', counters);
          var total = 0;
          counters.forEach(counter => {
            for (let key in counter) {
              console.log(`${key}: ${counter[key]}`);
              if (counter["count"] > 0) {
                $(counter["class_name"]).html(counter["count"]);
                total = total + +counter["count"];
              } else {
                $(counter["class_name"]).empty();
              }
            }
          })
        });



      } else if (data.action_type === 'notify') {
        console.log('AdministratorNotificationChannel notify', data);

        if (!$('#chat_container').hasClass(`channel_id_${data.chat_channel_id}`)) {
          console.log('DOES NOT hasClass so send the toast');
          $(toast_container).append(data.content);
          $('.toast').toast({
            delay: 7000
          });
          $(`.toast.${data.message_type}_toast_${data.message_id}`).toast("show");

        } else {
          console.log('DOES hasClass so DO NOT send the toast');
        }



      } else {


        if ($('#administrator-notification-datatable').length) {
          location.reload();
        } else {
          if (data.send_method === 'feed') {
            console.log('we will now send via feed');
            $(administrator_notifications_container).prepend(data.content);
            $(administrator_notifications_menu_container).prepend(data.content);
          } else if (data.send_method === 'toast') {
            console.log('we will now send via toast');
            $(toast_container).append(data.content);
            $('.toast').toast({
              delay: 7000
            });
            $(`.toast.administrator_notification_${data.administrator_notification_id}`).toast("show");
          }
          $('.uncleared_feed_notifications_count').html(data.uncleared_feed_notifications_count);
        }
      }

    }
  });
});
