import consumer from "./consumer"

$(document).ready(function() {

  const toast_container = $('#toast_container');
  const user_notifications_container = $('#user_notifications_container');
  const user_notifications_menu_container = $('#user_notifications_menu_container');

  var user_channel_id = $("meta[name='user_channel_id']").attr("content");
  console.log('UserNotificationChannel user_channel_id', user_channel_id);

  const chatChannel = consumer.subscriptions.create({
    channel: "UserNotificationChannel",
    channel_id: user_channel_id
  }, {
    connected() {
      console.log('UserNotificationChannel connected');
    },

    disconnected() {
      console.log('UserNotificationChannel disconnected');
    },

    received(data) {
      console.log('UserNotificationChannel received data', data);

      if (data.action_type === 'update_counters') {
        console.log('UserNotificationChannel update_counters', data);

        if (!$('#chat_container').hasClass(`channel_id_${data.chat_channel_id}`)) {
          console.log('DOES NOT hasClass so update the counters');
          $('.uncleared_all_count').html(data.uncleared_all_count);
          $('.uncleared_feed_notifications_count').html(data.uncleared_feed_notifications_count);
          $('.uncleared_messages_count').html(data.uncleared_messages_count);
          $('.uncleared_user_messages_count').html(data.uncleared_user_messages_count);
          $('.uncleared_user_meeting_messages_count').html(data.uncleared_user_meeting_messages_count);
          $('.dropdown-menu.notifications .notifications_fallback').hide();
        }


      } else if (data.action_type === 'notify') {
        console.log('UserNotificationChannel notify', data);

        if (!$('#chat_container').hasClass(`channel_id_${data.chat_channel_id}`)) {
          console.log('DOES NOT hasClass so send the toast');
          $(toast_container).append(data.content);
          $('.toast').toast({
            delay: 7000
          });
          $(`.toast.${data.message_type}_toast_${data.message_id}`).toast("show");

        } else {
          console.log('DOES hasClass so DO NOT send the toast');
        }



      } else {
        if ($('#user_notifications_container').length) {
          location.reload();
        } else {
          if (data.send_method === 'feed') {
            console.log('we will now send via feed');
            $(user_notifications_container).prepend(data.content);
            $(user_notifications_menu_container).prepend(data.content);
          } else if (data.send_method === 'toast') {
            console.log('we will now send via toast');
            $(toast_container).append(data.content);
            $('.toast').toast({
              delay: 7000
            });
            $(`.toast.user_notification_${data.user_notification_id}`).toast("show");
          }
        }
      }

      // $('.uncleared_all_count').html(data.uncleared_all_count);
      // $('.uncleared_feed_notifications_count').html(data.uncleared_feed_notifications_count);
      // $('.dropdown-menu.notifications .notifications_fallback').hide();
    }
  });

});
