import consumer from "./consumer"

$(document).ready(function () {

  const user_meeting_container = $('#user_meeting_container');
  const user_meeting_channel_id = user_meeting_container.data('channel-id');
  console.log('UserMeetingChannel user_meeting_channel_id', user_meeting_channel_id);

  let typingTimer = null;

  const chatChannel = consumer.subscriptions.create({
    channel: "UserMeetingChannel",
    channel_id: user_meeting_channel_id
  }, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log('UserMeetingChannel connected');
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      console.log('UserMeetingChannel disconnected');
    },

    received(data) {
      console.log('UserMeetingChannel received data', data);
      location.reload();
    },

    speak(message_text, channel_id, sent_by_id, sent_by_type) {
      console.log('UserMeetingChannel speak', message_text, channel_id, sent_by_id, sent_by_type);
      this.perform('speak', {message_text, channel_id, sent_by_id, sent_by_type});
    },

    is_typing(channel_id, sent_by_id, sent_by_type) {
      console.log('UserMeetingChannel is_typing', channel_id, sent_by_id, sent_by_type);
      this.perform('is_typing', {channel_id, sent_by_id, sent_by_type});
    }
  });

});
