import consumer from "./consumer"

$(document).ready(function () {

  const user_messages_container = $('#user_messages_container');
  const user_chat_channel_id = user_messages_container.data('user-chat-channel-id');
  console.log('UserChatChannel user_chat_channel_id', user_chat_channel_id);

  const current_user_id = user_messages_container.data('current-user-id');
  console.log('UserChatChannel current_user_id', current_user_id);


  let typingTimer = null;

  const userChatChannel = consumer.subscriptions.create({
    channel: "UserChatChannel",
    channel_id: user_chat_channel_id
  }, {
    connected() {
      console.log('UserChatChannel connected');
    },

    disconnected() {
      console.log('UserChatChannel disconnected');
    },

    received(data) {
      console.log('UserChatChannel received data!', data);

      const user_id = data.user_id;
      const typing_activity_indicator = $(`.typing_activity_indicator.user_${user_id}_is_typing`);

      var isAtBottom;
      var windowSum = $('.imessage_user_chat')[0].scrollHeight - $('.imessage_user_chat').scrollTop() - $('.imessage_user_chat').innerHeight();

      console.log("windowSum", windowSum);
      if( windowSum < 30 ) {
        isAtBottom = true;
      }

      if (data.action_type === 'speak') {
        const class_name = current_user_id == user_id ? 'sent_by_self' : 'sent_by_other';

        const user_message = $(data['user_message']).addClass(class_name);

        user_messages_container.append(user_message);
        typing_activity_indicator.hide();

        if (class_name === 'sent_by_other') {
          console.log("UserChatChannel WE WILL MARK MESSAGES AS READ!");
          const chat_url = "/user_chats/" + data["channel_id"] + "/mark_as_read";
          console.log("UserChatChannel mark_as_read chat_url", chat_url);
          $.post(chat_url, response => console.log('UserChatChannel mark_as_read response', response));
          if (isAtBottom) {
            scrollToUserChatBottom();
          }
        } else {
          scrollToUserChatBottom();
        }

      } else if (data.action_type === 'is_typing') {
        console.log('UserChatChannel is_typing!');
        typing_activity_indicator.show();
        clearTimeout(typingTimer);
        startTimer();
        if (isAtBottom) {
          scrollToUserChatBottom();
        }
      }
    },

    speak(text, channel_id, user_id) {
      console.log('UserChatChannel speak', text, channel_id, user_id);
      this.perform('speak', {text, channel_id, user_id});
    },

    is_typing(channel_id, user_id) {
      console.log('UserChatChannel is_typing', channel_id, user_id);
      this.perform('is_typing', {channel_id, user_id});
    }

  });


  $(document).on('keypress', '[data-behavior~=user_chat_speaker]', function(event) {
    console.log('keypress', event);
    console.log('event.key', event.key);

    console.log('keypress user_chat_channel_id', user_chat_channel_id);
    console.log('keypress current_user_id', current_user_id);



    // const user_id = $(this).data('user-id');
    // const channel_id = $(this).data('user-chat-channel-id');

    // console.log 'event.target.value', event.target.value
    if ((event.key === 'Enter') && (event.target.value !== '')) {
      console.log('submit via return key', event.target.value);
      userChatChannel.speak(event.target.value, user_chat_channel_id, current_user_id);
      event.target.value = '';
      return event.preventDefault();
    } else {
      return userChatChannel.is_typing(user_chat_channel_id, current_user_id);
    }
  });


  $('.user_chat_form_elements button').click(function(event) {
    console.log('.chat_form_elements button click event', event);
    const input_element = $(this).parent().find('input');
    console.log('input_element', input_element);
    const input_element_value = input_element.val();
    console.log('input_element_value', input_element_value);

    if (input_element_value !== '') {
      userChatChannel.speak(input_element_value, user_chat_channel_id, current_user_id);
      input_element.val('');
    }
    return event.preventDefault();
  });

  $('.scroll_to_bottom_button_user_chat').on('click', function(event) {
    console.log('user_chat_channel click!', event);
    scrollToUserChatBottom();
    return event.preventDefault();
  })

  $('.imessage_user_chat').on('scroll', function() {
    if (($(this).scrollTop() + $(this).innerHeight()) + 75 >= $(this)[0].scrollHeight) {
      $('.scroll_to_bottom_button_user_chat').fadeOut(250);
    } else {
      $('.scroll_to_bottom_button_user_chat').fadeIn(100);
    }
  });

  var startTimer = () => typingTimer = window.setTimeout((() => $(".typing_activity_indicator").fadeOut('slow')), 5000);

  var scrollToUserChatBottom = () => $('.imessage_user_chat').stop().animate(
    {scrollTop: user_messages_container.prop("scrollHeight")}
  , 500);

  const jumpToUserChatBottom = () => $('.imessage_user_chat').scrollTop(user_messages_container.prop("scrollHeight"));

  return jumpToUserChatBottom();




});

